import React from 'react';
import styled from 'styled-components';

const PodcastStyle = styled.section`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: auto;
    padding: 25px;
    
    ${({theme})=> theme.media.tablet}{
        width: 80%;
    }
`

const IframeStyle = styled.iframe`
    /* border-radius:12px; */
    width: 100%;
    height: 352px;
`


const Podcast = () => {
    return (
        <PodcastStyle>
            <IframeStyle src="https://open.spotify.com/embed/show/2QSdeid4vWpvk54VhvbmFd?utm_source=generator&t=0" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></IframeStyle>
        </PodcastStyle>
    );
}

export default Podcast;
