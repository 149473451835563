import React from 'react'
import styled from 'styled-components'

const VideoDiv= styled.div`
    overflow: hidden;
    position: relative;
    width: 100%;
    /* grid: 1/1; */
    /* height: 60vh; */
    margin: 30px auto;

    ${({theme})=> theme.media.tablet}{
        width: 80%;
        aspect-ratio: 16/9;
    }

    ::after{
        padding-top: 56.25%;
        display: block;
        content: '';
    }

    iframe{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`
export default function YouTube() {
  return (
    <VideoDiv>
        <iframe 
            src="https://www.youtube.com/embed/B8ZUovbc3tc?si=PtlMCibm6bJfn2rN"
            title="Ultramaraton Rowerowy 2023" 
            frameborder="0" 
            allow="accelerometer; autoplay; gyroscope; picture-in-picture; loop; muted;" 
            
            allowFullScreen/>
    </VideoDiv> 

  )
}


