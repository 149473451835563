import React from 'react';
import styled from 'styled-components';
import addToMailchimp from 'gatsby-plugin-mailchimp'


const News = styled.section`
    display: flex;
    flex-direction: column;
    max-width: 90vw;
    border: 1px solid dotted;
    justify-content: center;
    text-align: center;
    border: 3px solid black;
    border-bottom: 3px solid black;
    margin: 20px auto;
    box-shadow: 5px 10px #888888;


    h3{
        font-size: 1.5em;
        font-family: "Righteous", cursive;
    }

`

const FormN = styled.div`
        
        
        form{
            display: flex;
            flex-direction: column;
            padding: 20px 0px;
            
            input{
                font-size: 1.2em;
                margin: 20px auto;
                width: 40vw;
                border: none;
                padding: 10px 20px;
                border-bottom: 1px solid black;
            }
    
            button{
                margin:  10px auto;
                color: whitesmoke;
                background-color: #B68A65;
                border: 2px solid #B68A65;
                /* border: 10px solid white; */
                /* border-radius: 50px; */
                padding: 15px 30px;
                text-decoration: none;
                cursor: pointer;
                transition: 0.4s linear;
                width: 200px;
            
                &:hover{
                    /* border: 10px solid #B68A65; */
                    box-shadow: 12px 12px 2px 1px #B68A65;
                    background-color: transparent;
                    color: #B68A65;
                    border: 2px solid #B68A65;
                }
            
            }
        }
`


class Newsletter extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            email: '',
            message: 'Zapisz się na wstępną listę chętnych.'
        }
    }

    changeEmailHandler = (event) => {
        this.setState({email: event.target.value})
    }

    handleSubmit = e => {
        e.preventDefault();
        this.setState({message: 'Super. Teraz już nie przegapisz zapisów i przygody gravelowej Doliną Bugu.'})
        addToMailchimp(this.state.email) // listFields are optional if you are only capturing the email address.
        .then(data => {
        // I recommend setting data to React state
        // but you can do whatever you want (including ignoring this `then()` altogether)
        console.log(data)
        })
        .catch(() => {
        // unnecessary because Mailchimp only ever
        // returns a 200 status code
        // see below for how to handle errors
        })

        this.setState({email: ''})
    }

    render() {
    return (
        <>
            <News>
                <h3>{this.state.message}</h3><br></br>
                <p>Jeśli chcesz jako pierwszy otrzymać inforamcję o rozpoczęciu zapisów zostaw nam swojego maila.</p>
                <FormN>
                    <form className='subscribe' onSubmit={this.handleSubmit}>
                        <input tyle="email" value={this.state.email} onChange={this.changeEmailHandler} name="EMAIL" id='mce-EMAIL' className='subscribe-email' placeholder='Twój e-mail' required/>
                        <button type='submit'>Poinformuj mnie</button>
                    </form>
                </FormN>
            </News>
        </>
        );
  }
}

export default Newsletter