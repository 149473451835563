import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import {GiCycling} from 'react-icons/gi'
import {GiMountainCave} from 'react-icons/gi'
import {GiSandsOfTime} from 'react-icons/gi'

import { AnimationOnScroll } from 'react-animation-on-scroll';

const InfoDist = styled.section`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding-bottom: 50px;

    h3{
        font-size: 1.5em;
        text-align: center;
        font-weight: 100;
        padding: 20px 0px;
    }
    ${({theme})=> theme.media.tablet}{
        margin: 0 10% ;
    }
`
const LinkTrack = styled(Link)`
    text-decoration: none;
    color: black;
    /* margin: 5px 5px; */
    /* display: flex;
    flex-wrap: wrap; */
`


const Item = styled.div`
    padding: 10px 30px;
    margin: 10px 10px;
    background: rgb(68,105,113);
    background: linear-gradient(180deg, rgba(68,105,113,0.5) 0%, rgba(182,138,101,0.5) 100%);
    /* border: 5px solid #FFFFFF; */
    border-radius: 25px;
    -webkit-box-shadow: 15px 23px 16px -17px rgba(66, 68, 90, 1);
  -moz-box-shadow: 15px 23px 16px -17px rgba(66, 68, 90, 1);
  box-shadow: 15px 23px 16px -17px rgba(66, 68, 90, 1);
    /* display: flex;
    justify-content: space-around;
    flex-wrap: wrap; */
    
    h2{
        text-align: right;
        text-transform: uppercase;
        text-decoration: underline;
        font-family: "Righteous", cursive;
        font-size: 2.5em;
        padding-bottom: 20px;
    }
    div{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 10px;
        p{
            text-align: center;
            font-size: 2em;
            line-height: 1;
            margin-left: 30px;
        }
        .icon{
            width: 64px;
            height: 64px;
        }
    }
`

const Title = styled.h3`
    text-align: center;
    font-size: 2em;
    margin: auto;
    padding: 30px 0px 10px 0px;
    font-weight: 500;
    font-family: "Righteous", cursive;
    color: #446971;
`
const Info = () => {
    return (
        <>  
            <Title>Trasa</Title>
            <InfoDist>
                <h3>290/550 km jazdy wśród malowniczych pejzaży Nadbużańskich wsi i rozlewisk. To wyzwanie czeka na Ciebie. W trudnym terenie, samotnie, z dala od cywilizacji zmierzysz się sam ze sobą. Czy dasz radę ? Wierzymy, że tak. Zajechany, utytłany w błocie, śmierdzący będziesz triumfował na lini mety, wspominając do końca życia trasę Ultra Race Dolina Bugu.</h3>
                
                <LinkTrack to='/Trasa'>
                    <Item>
                        <h2>gigante</h2>
                        <div><AnimationOnScroll animateIn="animate__fadeIn" duration={1} delay={0.4} animateOnce={true}>
                            <GiCycling className='icon'/>
                        </AnimationOnScroll>
                            <p>550km<br></br>przygody</p>         
                        </div>
                        <div><AnimationOnScroll animateIn="animate__fadeIn" duration={1} delay={0.4} animateOnce={true}>
                            <GiMountainCave className='icon'/>
                        </AnimationOnScroll>
                            <p>2600m<br></br>wspinaczki</p>         
                        </div>
                        <div>
                            <AnimationOnScroll animateIn="animate__fadeIn" duration={1} delay={0.4} animateOnce={true}>
                                <GiSandsOfTime className='icon'/>
                            </AnimationOnScroll>
                            <p>60h<br></br>limit czasu</p>         
                        </div>
                    </Item>
                    
                    
                </LinkTrack>
                <LinkTrack to='/Trasa'>
                <Item>
                        <h2>classico</h2>
                        <div>
                            <AnimationOnScroll animateIn="animate__fadeIn" duration={1} delay={0.4} animateOnce={true}>
                                <GiCycling className='icon'/>
                            </AnimationOnScroll>
                            <p>290km<br></br>przygody</p>         
                        </div>
                        <div>
                            <AnimationOnScroll animateIn="animate__fadeIn" duration={1} delay={0.4} animateOnce={true}>
                                <GiMountainCave className='icon'/>
                            </AnimationOnScroll>
                            <p>1500m<br></br>wspinaczki</p>         
                        </div>
                        <div>
                            <AnimationOnScroll animateIn="animate__fadeIn" duration={1} delay={0.4} animateOnce={true}>
                                <GiSandsOfTime className='icon'/>
                            </AnimationOnScroll>
                            <p>36h<br></br>limit czasu</p>         
                        </div>
                    </Item>
                </LinkTrack>
            </InfoDist>
        </>
    );
}

export default Info;