import React from "react";
import { useEffect, useState } from "react";
import styled from 'styled-components'
// import "./App.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';

const StyleCountTime = styled.section`
  background-color: #446971;
  width: 100vw;
`
const CountTitle = styled.h3`
    color: white;

    text-align: center;
    margin-top: 10px;
    font-size: 1.6em;
    font-weight: bold;
    strong{
        color: white;
    }
    ${({theme})=> theme.media.tablet}{
        font-size: 2.5em;
    }
    
`
const Info = styled.p`
    text-align: center;
    
`

const TimeStyle = styled.section`
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin: auto;
    max-width: 70vw;
    padding-top: 10px;

    span{
        padding: 10px;
        margin: 5px;
        text-align: center;
        color: white;
        
        h5{
            font-size: 2em;
            color: white;
            font-weight: bold;
        }
        
        p{
            font-size: 16px;
            color: white;
            font-weight: light;
            
        }
        
    }
    ${({theme})=> theme.media.tablet}{
            max-width: 600px;
            justify-content: center;
            span{
                padding: 15px;
                margin: 20px;
                h5{
                    font-size: 3.5em;
                }
                p{
                    font-size: 1.2em;
                }
                
            }
    }
`


function Countdown() {
  const calculateTimeLeft = () => {
    const difference = +new Date("2024-08-30T07:00:00+01:00") - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        day: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor(difference / (1000 * 60 * 60) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  return (
    <StyleCountTime>
      {timeLeft.day || timeLeft.hours || timeLeft.minutes || timeLeft.seconds ? (
          <><br></br>
          <CountTitle><AnimationOnScroll animateIn="animate__zoomIn" duration={2} animateOnce={true}>Do startu Doliny Bugu pozostało</AnimationOnScroll></CountTitle>
          {/* <CountTitle>Zapisy otwieramy <strong>23.02.2024</strong> o godzinie <strong>20:00</strong></CountTitle> */}
          {/* <Info>Do rozpoczęcia zapisów pozostało</Info> */}
          <TimeStyle>
          <span><h5>{timeLeft.day < 10 ? "0"+timeLeft.day : timeLeft.day}</h5><p>dni</p></span>
          {/* <span><h5>:</h5></span> */}
          <span><h5>{timeLeft.hours < 10 ? "0"+timeLeft.hours : timeLeft.hours}</h5><p>godzin</p></span>
          {/* <span><h5>:</h5></span> */}
          <span><h5>{timeLeft.minutes < 10 ? "0"+timeLeft.minutes : timeLeft.minutes}</h5><p>minut</p></span>
          {/* <span><h5>:</h5></span> */}
          <span><h5>{timeLeft.seconds < 10 ? "0"+timeLeft.seconds : timeLeft.seconds}</h5><p>sekund</p></span>
          </TimeStyle>
        </>
      ) : (
        <p></p>
      )}
    </StyleCountTime>
  );
}

export default Countdown;