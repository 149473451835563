import * as React from "react"
import { SEO } from "../components/Seo"
import Layout from "../components/Layout"

import Hero from "../components/Hero"
import Info from "../components/Info"
import Sponsor from "../components/Sponsor"
import Countdown from "../components/countdown"

import Podcast from "../components/Podcast"
import YouTube from "../components/YouTube"
import GaleriaSection from "../components/GaleriaSection"
import Newsletter from "../components/Newslatter"


const IndexPage = () => {
  return (
    <Layout>
      <Hero/>
      {/* <Countdown/> */}
      <YouTube/>
      <Info/>
      {/* <Newsletter/> */}
      <GaleriaSection/>
      <Sponsor />
      <Podcast/>
    </Layout>
  )
}

export default IndexPage

export const Head = () => (
  <SEO title="Ultramaraton Rowerowy" description="Ultramaraton Rowerowy wzdłuż Bugu - jednej z ostatnich dzikich rzek w Europie. 
  Trasa prowadzi przez województwo mazowieckie, podlaskie, lubelskie. Przeżyj rowerową przygodę."/>
)