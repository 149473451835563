import React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image'
import { AnimationOnScroll } from 'react-animation-on-scroll';

const Sposor = styled.section`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    
    h2{
        font-size: 1.6em;
        font-weight: 300;
        text-align: center;
    }

    div{
        display: block;
        padding: 13px;
        margin: auto;
        justify-content: center;
        div{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }
            .img{
                margin: 20px;
                /* padding-bottom: 40px; */
                width: auto;
                max-width: 250px;
                height: 100px;
            }  
            .patronat{
                margin: 20px;
                /* padding-bottom: 40px; */
                width: auto;
                max-width: 200px;
                height: auto;
            }
            
    }

    ${({theme})=> theme.media.tablet}{
        max-width: 100vw;
        margin: auto;
    }    
`


function Sponsor() {
  return (
    <Sposor>
        <div>
            <h2>WSPIERAJĄ NAS</h2>
            <div>
                <a href='https://ass-magic.com' target={"_blank"} rel="noreferrer" className='icon' >
                    <AnimationOnScroll animateIn="animate__zoomIn" duration={1} animateOnce={true}>
                        <StaticImage
                        src="../images/assmagic.png"
                        alt="Kremy przeciwko otarciom dla kolarzy i triathlonistów Ass Magic."
                        placeholder="blurred"
                        layout='fixed'
                        // className='marg'
                        loading='auto'
                        // width={120}
                        height={120}
                        ></StaticImage>
                    </AnimationOnScroll>
                </a>
                <a href='https://braciaziolkowscy.pl' target={"_blank"} rel="noreferrer" className='icon' >
                    <AnimationOnScroll animateIn="animate__zoomIn" duration={1} animateOnce={true}>
                        <StaticImage
                        src="../images/bracia_logo.png"
                        alt="Bracia Ziółkowscy Palarnia Kawy"
                        placeholder="blurred"
                        layout='constrained'
                        // className='marg'
                        loading='auto'
                        // width={120}
                        height={100}
                        ></StaticImage>
                    </AnimationOnScroll>
                </a>
                {/* <a href='https://braciaziolkowscy.pl' target={"_blank"} rel="noreferrer" className='icon' >
                    <AnimationOnScroll animateIn="animate__zoomIn" duration={1} animateOnce={true}>
                        <StaticImage
                        src="../images/bracia_logo.png"
                        alt="Bracia Ziółkowscy Palarnia Kawy"
                        placeholder="blurred"
                        layout='constrained'
                        className='marg'
                        loading='auto'
                        width={120}
                        height={120}
                        ></StaticImage>
                    </AnimationOnScroll>
                </a> */}
                <a href='https://browarfortuna.pl/' target={"_blank"} rel="noreferrer" className='icon'>
                    <AnimationOnScroll animateIn="animate__zoomIn" duration={1} animateOnce={true}>
                        <StaticImage
                        src="../images/fortuna.png"
                        alt="Browar Fortuna"
                        placeholder="blurred"
                        layout='constrained'
                        className='img'
                        loading='auto'
                        height={80}
                        width={120}
                        
                        ></StaticImage>
                    </AnimationOnScroll>
                </a>
                <a href='https://www.brytonsport.com/#/' target={"_blank"} rel="noreferrer">
                    <AnimationOnScroll animateIn="animate__zoomIn" duration={1} delay={0.4} animateOnce={true}>
                        <StaticImage
                            src="../images/bryton.png"
                            alt="bryton przygotuj się na przygodę"
                            placeholder="blurred"
                            layout='constrained'
                            className='img'
                            loading='auto'
                            ></StaticImage>
                    </AnimationOnScroll>
                </a>
                <a href='https://eurowafel.pl/Home-2.html' target={"_blank"} rel="noreferrer" className='icon'>
                    <AnimationOnScroll animateIn="animate__zoomIn" duration={1} animateOnce={true}>
                        <StaticImage
                        src="../images/eurowafel.png"
                        alt="Euro Wafel"
                        placeholder="blurred"
                        layout='constrained'
                        className='img'
                        loading='auto'
                        height={90}
                        // width={178}
                        
                        ></StaticImage>
                    </AnimationOnScroll>
                </a>
                
        
                <a href='https://mielnik.com.pl' target={"_blank"} rel="noreferrer" className='icon'>
                    <AnimationOnScroll animateIn="animate__zoomIn" duration={1} animateOnce={true}>
                        <StaticImage
                        src="../images/mielnikk.png"
                        alt="Gmina Mielnik"
                        // placeholder="blurred"
                        layout='fixed'
                        // className='img'
                        loading='auto'
                        height={90}
                        // width={80}
                        // width={178}
                        
                        ></StaticImage>
                    </AnimationOnScroll>
                </a>
                <a href='https://www.wyszkow.pl' target={"_blank"} rel="noreferrer" className='icon'>
                    <AnimationOnScroll animateIn="animate__zoomIn" duration={1} animateOnce={true}>
                        <StaticImage
                        src="../images/wyszkow.png"
                        alt="Gmina Wyszków"
                        placeholder="blurred"
                        layout='fixed'
                        // className='img'
                        loading='auto'
                        height={80}
                        // width={120} 
                        
                        ></StaticImage>
                    </AnimationOnScroll>
                </a>
                <a href='https://www.morecycling.pl/' target={"_blank"} rel="noreferrer">
                    <AnimationOnScroll animateIn="animate__fadeIn" duration={1} delay={0.4} animateOnce={true}>
                        <StaticImage
                            src="../images/l_more.jpeg"
                            alt="more cycling odzież kolarska"
                            placeholder="blurred"
                            layout='fixed'
                            // className='img'
                            height={80}
                            ></StaticImage>
                    </AnimationOnScroll>
                </a>
                <a href='https://morehomecycling.pl' target={"_blank"} rel="noreferrer">
                    <AnimationOnScroll animateIn="animate__zoomIn" duration={1} delay={0.4} animateOnce={true}>
                        <StaticImage
                            src="../images/logo_home.jpeg"
                            alt="more home dom dla kolarzy"
                            placeholder="blurred"
                            layout='constrained'
                            className='img'
                            ></StaticImage>
                    </AnimationOnScroll>
                </a>                 
                <a href='https://www.polska.travel/' target={"_blank"} rel="noreferrer">
                    <AnimationOnScroll animateIn="animate__zoomIn" duration={1} delay={0.4} animateOnce={true}>
                        <StaticImage
                            src="../images/POT.png"
                            alt="moneslo - bliżej przyrody"
                            placeholder="blurred"
                            layout='constrained'
                            className='img'
                            ></StaticImage>
                    </AnimationOnScroll>
                </a>
                <a href='http://www.smoovelube.com' target={"_blank"} rel="noreferrer" className='icon'>
                    <AnimationOnScroll animateIn="animate__zoomIn" duration={1} animateOnce={true}>
                        <StaticImage
                        src="../images/smoove.jpg"
                        alt="Smoove Lube - smak na każdą pogodę "
                        placeholder="blurred"
                        layout='fixed'
                        // className='img'
                        loading='auto'
                        height={80}
                        
                        ></StaticImage>
                    </AnimationOnScroll>
                </a>
                <a href='http://www.mokterespol.com' target={"_blank"} rel="noreferrer" className='icon'>
                    <AnimationOnScroll animateIn="animate__zoomIn" duration={1} animateOnce={true}>
                        <StaticImage
                        src="../images/terespol.png"
                        alt="MCKSiR Terespol"
                        placeholder="blurred"
                        layout='fixed'
                        // className='img'
                        loading='auto'
                        height={80}
                        
                        ></StaticImage>
                    </AnimationOnScroll>
                </a>
                <a href='https://www.zentis.pl/przemys' target={"_blank"} rel="noreferrer">
                    <AnimationOnScroll animateIn="animate__zoomIn" duration={1} delay={0.4} animateOnce={true}>
                        <StaticImage
                            src="../images/zentis.png"
                            alt="Zentis - partner dla przemysłu przetwórczego"
                            placeholder="blurred"
                            layout='constrained'
                            className='img'
                            loading='auto'
                            ></StaticImage>
                    </AnimationOnScroll>
                </a>
            </div>
        </div>
        <div>
                    <h2>PATRONAT HONOROWY</h2>
                    <div>
                        <a href='https://www.bialapodlaska.pl' rel="noreferrer">
                            <AnimationOnScroll animateIn="animate__zoomIn" duration={1} delay={0.4} animateOnce={true}>
                                <StaticImage
                                    src="../images/biala_patronat.png"
                                    alt="Patronat Honorowy Prezydenta Miasta  Biała Podlaska"
                                    placeholder="blurred"
                                    layout='constrained'
                                    className='patronat'
                                    ></StaticImage>
                            </AnimationOnScroll>
                        </a>
                        <a href='/' rel="noreferrer">
                            <AnimationOnScroll animateIn="animate__zoomIn" duration={1} delay={0.4} animateOnce={true}>
                                <StaticImage
                                    src="../images/chelm.png"
                                    alt="miasto chełm"
                                    placeholder="blurred"
                                    layout='constrained'
                                    className='patronat'
                                    ></StaticImage>
                            </AnimationOnScroll>
                        </a>
                        <a href='/' target={"_blank"} rel="noreferrer">
                            <AnimationOnScroll animateIn="animate__zoomIn" duration={1} delay={0.4} animateOnce={true}>
                                <StaticImage
                                    src="../images/lp_logo2.png"
                                    alt="lasy państowe"
                                    placeholder="blurred"
                                    layout='constrained'
                                    // className='img'
                                    // width={300}
                                    height={120}
                                    ></StaticImage>
                            </AnimationOnScroll>
                        </a>
                        
                    </div>
                </div>
        
    </Sposor>
  )
}

export default Sponsor